var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":"","elevation":"1"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("Perbaikan")]),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"300px"},attrs:{"dense":"","autofocus":"","outlined":"","rounded":"","placeholder":"Search","append-icon":"mdi-magnify","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":"","title":"Refresh"},on:{"click":function($event){return _vm.getData(true)}}},[_c('v-icon',[_vm._v("mdi-sync")])],1),_c('v-btn',{attrs:{"icon":"","title":"Add"},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{staticClass:"mb-3 my-0",attrs:{"loading":_vm.datatable.loading,"loading-text":_vm.datatable.loadingText,"search":_vm.search,"headers":_vm.datatable.columns,"items":_vm.datatable.data},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","x-small":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"text":"","icon":"","x-small":""},on:{"click":function($event){return _vm.deleteData(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('rightbar',{staticClass:"d-inline",attrs:{"title":"Description"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._b({on:{"click":on}},'span',attrs,false),[_vm._v(_vm._s(item.description.substring(0, 50))+_vm._s((item.description.length > 50) ? '...' : ''))])]}}],null,true)},[_c('div',{staticClass:"ma-2"},[_c('v-textarea',{attrs:{"value":item.description,"outlined":"","dense":"","readonly":"","rows":"6","filled":""}})],1)])]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{attrs:{"loading":_vm.loading_save}},[_c('v-card-title',[_vm._v("Tambah Data")]),_c('v-card-text',[_c('app-date-time',{staticClass:"mb-2",attrs:{"type":"datetime-local","dense":"","placeholder":"Tanggal","label":"Tanggal","outlined":"","hide-details":""},model:{value:(_vm.fmData.action_date),callback:function ($$v) {_vm.$set(_vm.fmData, "action_date", $$v)},expression:"fmData.action_date"}}),_c('v-textarea',{staticClass:"mb-3",attrs:{"dense":"","hide-details":"","flat":"","outlined":"","label":"Deskripsi"},model:{value:(_vm.fmData.description),callback:function ($$v) {_vm.$set(_vm.fmData, "description", $$v)},expression:"fmData.description"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading_save},on:{"click":function($event){return _vm.saveData()}}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialog1 = !_vm.dialog1}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }