<template>
  <v-dialog v-model="dialog" persistent max-width="1200px" transition="dialog-transition">
    <v-card>
      <v-toolbar elevation="1">
        <v-toolbar-title>Find Fixed Asset</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>
        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-btn icon @click="hideDialog()" title="Close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="isMobile" class="pt-4">
        <v-text-field dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" hide-details single-line></v-text-field>
      </v-card-text>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
        <template v-slot:[`item.no_`]="{ item }">
          <a href="javascript:;" @click="selectItem(item)" class="d-block text-no-wrap">{{ item.no_ }}</a>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <span class="d-block text-no-wrap">{{ item.description }}</span>
        </template>

        <!-- Mobile Version -->
        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1">
              <a href="javascript:;" @click="selectItem(item)" class="d-block text-no-wrap">{{ item.no_ }}</a>
              <v-spacer></v-spacer>
              <v-chip v-if="item.area_code">{{ item.area_code }}</v-chip>
            </v-card-title>
            <v-card-subtitle class="my-0 pt-0">
              {{ item.description }}<br>
              Location Code: {{ item.location_code }}<br>
              FA Location Code: {{ item.fa_location_code }}
            </v-card-subtitle>
          </v-card>
        </template>
      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="hideDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,

      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 5
      },
      itemsPerPageOption: [5, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Description2", value: "description2", class: "text-no-wrap" },
          { text: "Subclass", value: "fa_subclass_code", class: "text-no-wrap" },
          { text: "Area Code", value: "area_code", class: "text-no-wrap" },
          { text: "Location Code", value: "location_code", class: "text-no-wrap" },
          { text: "FA Location Code", value: "fa_location_code", class: "text-no-wrap" },
          { text: "Responsibility", value: "responsibility", class: "text-no-wrap" },
          { text: "Serial No.", value: "serial_no_", class: "text-no-wrap" },
        ],
        data: [],
      },
    }
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    showDialog() {
      this.dialog = true;
      this.getData(true);
    },
    hideDialog() {
      this.dialog = false;
    },
    selectItem(item) {
      this.$emit("callback", item);
      this.dialog = false;
    },
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      const uri = "mjo/find-fixed-asset";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;
      this.datatable.loading = true;
      const params = {
        search: this.search,
        sort_by: sortBy,
        sort_desc: sortDesc,
        page: page,
        limit: itemsPerPage,
      }
      this.$axios
        .get(uri, {
          sessionStorage: !refresh,
          params: params
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
  }
}
</script>