<template>
  <div>
    <v-sheet :elevation="elevation">
      <v-data-table dense :items="dt_mjo_approval" :headers="mjo_approval_columns" :loading="loading">
        <template v-slot:top v-if="!hideToolbar">
          <v-toolbar flat dense>
            <v-toolbar-title>Approval</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon text @click="getData(true)" v-if="dataId != 0"><v-icon>mdi-sync</v-icon></v-btn>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
        <template v-slot:[`item.approved`]="{ item }">
          <!-- <span v-if="item.approved"> -->
          <v-icon v-if="item.approved" color="primary">mdi-check</v-icon>
          <v-icon v-if="!item.approved" color="light">mdi-minus</v-icon>
          <!-- </span> -->
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    dataList: [],
    dataId: {
      type: Number,
      default: 0
    },
    elevation: {
      type: String,
      default: "0"
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    }
  },
  computed:{
    dt_mjo_approval(){
      if(typeof this.dataList === 'undefined') return this.mjo_approval;
      
      return (this.dataList.length >= 0) ? this.dataList : this.mjo_approval
    },
    dt_id(){
      return (this.dataId >= 0) ? this.dataId : this.id
    },
  },
  data: () => {
    return {
      loading: false,
      mjo_approval_columns: [
        { text: "Approval", value: "approval_label", sortable: false },
        { text: "User", value: "username", sortable: false },
        { text: "Approval Status", value: "approved", sortable: false, class: "text-no-wrap", cellClass: 'text-center' },
        { text: "Approve Date", value: "approved_date", sortable: false },
      ],
      id: 0,
      mjo_approval: []
    };
  },
  methods: {
    async getData(refresh = false) {
      if (refresh == true) this.AxiosStorageRemove("GET", "mjo/approval-list");
      this.loading = true;
      await this.$axios
        .get("mjo/approval-list", {
          params: {
            id: this.dt_id,
          },
        })
        .then((res) => {
          this.mjo_approval = res.data.data.results;
          this.mjo_approval.forEach(item => {
            this.id = item.mjo_header_id;
          })
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    if (typeof this.dataId !== "undefined" && this.dataId > 0) {
      this.id = this.dataId;
      this.getData();
    } else {
      this.mjo_approval = this.dataList;
      this.mjo_approval.forEach(item => {
        this.id = item.mjo_header_id;
      })
    }
  },
};
</script>
