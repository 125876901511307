<template>
  <div>
    <v-alert color="warning" v-if="!showImage">
      No Image Found!
    </v-alert>
    <v-row v-else>
      <v-col cols="12" md="3" v-if="internal_mjo_header.image1">
        <a :href="internal_mjo_header.image1" target="_blank">
          <v-img :src="internal_mjo_header.image1"></v-img>
        </a>
      </v-col>
      <v-col cols="12" md="3" v-if="internal_mjo_header.image2">
        <a :href="internal_mjo_header.image2" target="_blank">
          <v-img :src="internal_mjo_header.image2"></v-img>
        </a>
      </v-col>
      <v-col cols="12" md="3" v-if="internal_mjo_header.image3">
        <a :href="internal_mjo_header.image3" target="_blank">
          <v-img :src="internal_mjo_header.image3"></v-img>
        </a>
      </v-col>
      <v-col cols="12" md="3" v-if="internal_mjo_header.image4">
        <a :href="internal_mjo_header.image4" target="_blank">
          <v-img :src="internal_mjo_header.image4"></v-img>
        </a>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    mjo_header: {}
  },
  data(){
    return {
      internal_mjo_header: this.mjo_header,
    }
  },
  computed: {
    showImage() {
      if ( (typeof this.internal_mjo_header.image1 == 'undefined') 
          && (typeof this.internal_mjo_header.image2 == 'undefined') 
          && (typeof this.internal_mjo_header.image3 == 'undefined') 
          && (typeof this.internal_mjo_header.image4 == 'undefined') ){
          return false;
      }
      if (this.internal_mjo_header.image1.length == 0 && this.internal_mjo_header.image2.length == 0 && this.internal_mjo_header.image3.length == 0 && this.internal_mjo_header.image4.length == 0)
        return false;

      return true;
    },
  },
  watch: {
    mjo_header(val){
      if(val){
        this.internal_mjo_header = val
      }
    }
  }
}
</script>