<template>
  <div>
    <v-card outlined elevation="1">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>Perbaikan</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-text-field dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-btn icon @click="add()" title="Add">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :search="search" :headers="datatable.columns" :items="datatable.data" class="mb-3 my-0">
        <template v-slot:[`item.id`]="{ item }">
          <v-btn text icon x-small @click="edit(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn text icon x-small @click="deleteData(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <rightbar title="Description" class="d-inline">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on:click="on">{{ item.description.substring(0, 50) }}{{ (item.description.length > 50) ? '...' : '' }}</span>
            </template>
            <div class="ma-2">
              <v-textarea :value="item.description" outlined dense readonly rows="6" filled></v-textarea>
            </div>
          </rightbar>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog1" max-width="500">
      <v-card :loading="loading_save">
        <v-card-title>Tambah Data</v-card-title>
        <v-card-text>
          <app-date-time type="datetime-local" dense v-model="fmData.action_date" placeholder="Tanggal" label="Tanggal" outlined hide-details class="mb-2"></app-date-time>
          <v-textarea dense hide-details flat outlined class="mb-3" v-model="fmData.description" label="Deskripsi"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveData()" :loading="loading_save">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog1 = !dialog1">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialog1: null,
      loading: null,
      loading_save: null,
      loading_delete: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20
      },
      itemsPerPageOption: [20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "#", value: "id", class: "text-no-wrap" },
          { text: "Action Date", value: "action_date", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Created By", value: "full_name", class: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap" },
        ],
        data: [],
      },

      fmData: {
        id: null,
        action_date: null,
        description: null,
      }
    }
  },
  methods: {
    async getData(refresh) {
      this.datatable.loading = true;
      const uri = "mjo/ca/list";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      await this.$axios
        .get(uri, {
          sessionStorage: !refresh,
          params: {
            mjo_header_id: this.id,
          }
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    add() {
      this.fmData.id = 0;
      this.fmData.action_date = null;
      this.fmData.description = "";
      this.dialog1 = true;
    },

    edit(item) {
      this.fmData.id = item.id;
      this.fmData.action_date = item.action_date;
      this.fmData.description = item.description;
      this.dialog1 = true;
    },

    closeDialog() {
      this.fmData.id = 0;
      this.fmData.action_date = null;
      this.fmData.description = "";
      this.dialog1 = false;
    },

    async saveData() {

      if (this.isEmpty(this.fmData.action_date)) {
        this.showAlert("warning", "Tanggal is required");
        return;
      }

      if (this.isEmpty(this.fmData.description)) {
        this.showAlert("warning", "Deskripsi is required");
        return;
      }



      this.loading_save = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();

      formData.append("mjo_header_id", this.id);
      formData.append("action_date", this.fmData.action_date);
      formData.append("description", this.fmData.description);
      formData.append("id", this.fmData.id);

      await this.$axios
        .post("mjo/ca/save", formData)
        .then((res) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.getData(true);
            this.closeDialog();
          }
        })
        .catch((error) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    deleteData(item) {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios
          .post("mjo/ca/delete", formData)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.getData(true);
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete document?", post);
    },
  },
  mounted() {
    this.getData(true);
  }
}
</script>