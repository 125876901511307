<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field label="FA No." v-model="internal_mjo_header.fa_no_" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
        <v-text-field label="Company ID" v-model="internal_mjo_header.fa_company_id" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
        <v-text-field label="Description" v-model="internal_mjo_header.fa_description" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
        <v-text-field label="Description 2" v-model="internal_mjo_header.fa_description2" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Area Code" v-model="internal_mjo_header.fa_area_code" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
        <v-text-field label="Location Code" v-model="internal_mjo_header.fa_location_code" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
        <v-text-field label="Responsibility" v-model="internal_mjo_header.fa_responsibility" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
        <v-text-field label="Serial No." v-model="internal_mjo_header.fa_serial_no_" hide-details outlined dense class="mb-2" readonly disabled></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    mjo_header: {}
  },
  data() {
    return {
      internal_mjo_header: this.mjo_header,
    }
  },
  watch: {
    mjo_header(val) {
      if (val) {
        this.internal_mjo_header = val
      }
    }
  }
}
</script>