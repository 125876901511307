<template>
  <card-expansion title="Document Info" class="mb-3" :isMinimize="false" no-padding>
    <v-simple-table dense fixed-header>
      <template v-slot:default>
        <tbody>
          <tr>
            <th>ID:</th>
            <td>{{ mjo_header.id }}</td>
          </tr>
          <tr>
            <th>Document Type:</th>
            <td>{{ mjo_header.document_type }}</td>
          </tr>
          <tr>
            <th>Source Document:</th>
            <td>{{ mjo_header.source_document }}</td>
          </tr>
          <tr>
            <th>Created At:</th>
            <td>{{ mjo_header.created_date }}</td>
          </tr>
          <tr>
            <th>Created By:</th>
            <td>
              <user-info :username="mjo_header.created_by_username">{{ mjo_header.created_by_username }}</user-info>
            </td>
          </tr>
          <tr>
            <th>Last Updated:</th>
            <td>
              <app-log-data-dialog module_name="mjo" :id="mjo_header.id">
                <a href="javascript:;">{{ mjo_header.last_updated }}</a>
              </app-log-data-dialog>
            </td>
          </tr>
          <tr>
            <th>Status:</th>
            <td><app-document-status v-model="mjo_header.status"></app-document-status></td>
          </tr>
          <tr>
            <th>Sub Status:</th>
            <td><app-document-status v-model="mjo_header.substatus"></app-document-status></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </card-expansion>
</template>

<script>
import UserInfo from '@/components/user/UserInfo.vue';
export default {
  props: {
    mjo_header: {}
  },
  components: {
    UserInfo,
  }
}
</script>